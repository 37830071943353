import React from 'react';
import {
    Route
} from 'react-router-dom';
import {
    Menu,
    Modal,
    message
} from 'antd';
import {
    PoweroffOutlined
} from '@ant-design/icons'

import Ajax from '../utils/Ajax';

import AccountSetting from './AccountSetting';
import Carousel from './Carousel';
import ProductCenter from './ProductCenter';
import SchemeList from './SchemeList';
import Printer from './Printer';
import PrintConsumables from './PrintConsumables';
import SoftwareCenter from './SoftwareCenter';
import CompanyIntroduce from './CompanyIntroduce';
import CompanyResults from './CompanyResults';
import Recruitment from './Recruitment';
import ConcatUs from './ConcatUs';
import Feedback from './Feedback';
import AddProduct from './AddProduct';
import AddScheme from './AddScheme';

export default class Main extends React.Component{

    constructor(){
        super();

        this.state = {
            current:'0',
            childCurrent:'0',
            visible:false,
            errMsg:'',
            menuList:[
                '账号信息',
                '首页',
                '产品中心',
                '解决方案',
                '关于我们',
                '联系我们'
            ],
            menuChildList:[
                [
                    {
                        label:'账户设置',
                        value:'AccountSetting',
                        component:AccountSetting
                    }
                ],
                [
                    {
                        label:'配置轮播图',
                        value:'Carousel',
                        component:Carousel
                    },
                    {
                        label:'产品中心',
                        value:'ProductCenter',
                        component:ProductCenter
                    },
                    {
                        label:'解决方案',
                        value:'SchemeList',
                        component:SchemeList
                    },
                ],
                [
                    {
                        label:'打印设备',
                        value:'Printer',
                        component:Printer
                    },
                    {
                        label:'打印耗材',
                        value:'PrintConsumables',
                        component:PrintConsumables,
                    },
                    {
                        label:'软件中心',
                        value:'SoftwareCenter',
                        component:SoftwareCenter
                    },
                ],
                [
                    {
                        label:'解决方案',
                        value:'SchemeList',
                       // component:SchemeList
                    }
                ],
                [
                    {
                        label:'公司介绍',
                        value:'CompanyIntroduce',
                        component:CompanyIntroduce
                    },
                    {
                        label:'公司业绩',
                        value:'CompanyResults',
                        component:CompanyResults
                    },
                    {
                        label:'招贤纳士',
                        value:'Recruitment',
                        component:Recruitment
                    },
                ],
                [
                    {
                        label:'联系我们',
                        value:'ConcatUs',
                        component:ConcatUs
                    },
                    {
                        label:'意见反馈',
                        value:'Feedback',
                        component:Feedback
                    }
                ],
            ]
        };
    }

    componentDidMount(){
        let { menuChildList } = this.state;
        let location = this.props.location.pathname

        if(location === '/'){
            this.props.history.push('/AccountSetting');

            return;
        }

        location = location.slice(1,);

        menuChildList.map((item,i) => {

            item.map((item1,index) => {
                if(location == item1.value){
                    this.setState({ 
                        current: i + '',
                        childCurrent: index + ''
                    });
                }
            })
        })
    }


    handleClick = (e) => {
        let { menuChildList } = this.state;

        this.setState({ 
            current: e.key,
            childCurrent:'0'
        },() => {
            this.props.history.push(menuChildList[e.key][0].value);
        });
    }

    childHandleClick = (e) => {
        let { menuChildList, current } = this.state;

        this.setState({ 
            childCurrent:e.key
        },() => {
            this.props.history.push(menuChildList[current][e.key].value);
        });
    }

    loginOut = () => {
        Ajax('sjy/sso/officialwebsite/loginOut')
            .then((res) => {
                message.success(res.errMsg);
                this.props.history.push('/login');
            })
    }

    render(){
        let {
            current,
            menuChildList,
            childCurrent,
            menuList,
            visible,
            errMsg
        } = this.state;
        return (
            <div className='main'>
                <div className='main-title'>
                    <div className='main-title__text'>官网管理系统</div>
                    <Menu onClick={this.handleClick} selectedKeys={[current]} mode="horizontal">
                        <Menu.Item key='0'>账号信息</Menu.Item>
                        <Menu.Item key='1'>首页</Menu.Item>
                        <Menu.Item key='2'>产品中心</Menu.Item>
                        <Menu.Item key='3'>解决方案</Menu.Item>
                        <Menu.Item key='4'>关于我们</Menu.Item>
                        <Menu.Item key='5'>联系我们</Menu.Item>
                    </Menu>
                    <div className='main-title__exit' onClick={ this.loginOut }>
                        <PoweroffOutlined />
                        <span>退出</span>
                    </div>
                </div>
                <div className='main-body'>
                    <div className='main-body__left'>
                        <Menu theme='dark' onClick={this.childHandleClick} selectedKeys={[childCurrent]}>
                            {
                                menuChildList[current].map((item,index) => {
                                    return <Menu.Item key={index}>{item.label}</Menu.Item>
                                })
                            }
                        </Menu>
                    </div>
                    <div className='main-body__right'>
                        <div className='main-body__right-title'>{menuList[current]} / {menuChildList[current][childCurrent].label}</div>
                            <Route path='/AddProduct' render={
                                (args) => <AddProduct {...args} Ajax={ Ajax } showTip={(errMsg) => { this.setState({visible: true,errMsg})}}/>
                            }/>
                            <Route path='/AddScheme' render={
                                (args) => <AddScheme {...args} Ajax={ Ajax } showTip={(errMsg) => { this.setState({visible: true,errMsg})}}/>
                            }/>
                            {
                                menuChildList.map((item) => item.map((item) => { 
                                    if(item.component){
                                        return <Route path={ '/'+item.value } render={
                                            (args) => 
                                            {
                                                return <item.component {...args} Ajax={ Ajax } showTip={(errMsg) => { this.setState({visible: true,errMsg})}}/>
                                            }
                                        }/>;
                                    }
                                }
                                ))
                            }
                    </div>
                    <Modal
                        title='提示'
                        visible={ visible }
                        okText='确定'
                        cancelText='取消'
                        onOk={() => this.setState({visible:false})}
                        onCancel={() => this.setState({visible:false})}
                    >
                        <div style={{textAlign:'center'}}>
                            { errMsg }
                        </div>
                    </Modal>
                </div>
            </div>
        )
    }
}