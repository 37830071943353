import React from 'react';
import {
    Button,
    Input,
    Form,
    Tabs,
    Select,
    Modal
} from 'antd';
import { UserOutlined,LockOutlined } from '@ant-design/icons';
import Ajax from '../utils/Ajax';

const FormItem = Form.Item;
const { TabPane } = Tabs;
const Option = Select.Option;
const { Search } = Input;

class Login extends React.Component {

    state = {
        activeKey:'1',
        validateImg:'',
        visible:false,
        code:''
    }

    uformRef = React.createRef();
    sformRef = React.createRef();

    componentDidMount(){

        if(document.readyState == 'complete'){
  
            var canvas = document.getElementById("sakura");
    
            try {
        
                makeCanvasFullScreen(canvas);
        
                gl = canvas.getContext('experimental-webgl');
        
            } catch(e) {
        
                alert("WebGL not supported." + e);
        
                console.error(e);
        
                return;
        
            }
        
            
        
            window.addEventListener('resize', onResize);
            
        
            setViewports();
        
            createScene();
        
            initScene();
        
            
        
            timeInfo.start = new Date();
        
            timeInfo.prev = timeInfo.start;
        
            animate();
        }

        window.addEventListener('load', function(e) {
            var canvas = document.getElementById("sakura");
        
            try {
        
                makeCanvasFullScreen(canvas);
        
                gl = canvas.getContext('experimental-webgl');
        
            } catch(e) {
        
                alert("WebGL not supported." + e);
        
                console.error(e);
        
                return;
        
            }
        
            
        
            window.addEventListener('resize', onResize);
            
        
            setViewports();
        
            createScene();
        
            initScene();
        
            
        
            timeInfo.start = new Date();
        
            timeInfo.prev = timeInfo.start;
        
            animate();
        
        });
    }

    componentWillUnmount(){
        cancelAnimationFrame(requestAnimationFrameTimer);
    }

    onFinish = (value) => {

        Ajax('sjy/sso/officialwebsite/loginByPassword',value).then((res) => {
            this.props.history.push('/AccountSetting');
        })
    }

    onFinish1 = (value) => {
        Ajax('sjy/sso/officialwebsite/loginByValidCode',value).then((res) => {
            this.props.history.push('/AccountSetting');
        })
    }

    validate = () => {
        let phone = this.sformRef.current.getFieldValue('phone');

        Ajax('sjy/sso/validate',{phone}).then((res) => {
            this.setState({
                visible:true,
                validateImg:'data:image/png;base64,' + res.data
            })
        })
    }

    sendLoginMSM = () => {
        let { code } = this.state;
        let phone = this.sformRef.current.getFieldValue('phone');

        Ajax('sjy/sso/sendLoginMSM',{ code,phone }).then((res) => {
            this.setState({ visible:false })
        })
    }
    

    render() {
        let { activeKey, visible, validateImg, code } = this.state;

        const prefixSelector = (
            <Form.Item name="prefix" noStyle>
              <Select style={{ width: 70 }}>
                <Option value="86">+86</Option>
              </Select>
            </Form.Item>
          );

        return (
            <div className='login'>
                <canvas id="sakura"></canvas>
                <div className='login-box'>
                    <Tabs renderTabBar={() => { return ''}} activeKey={ activeKey } animated={ true }>
                        <TabPane key='1'>
                            <div className='login-box__title'>账号密码登录</div>
                            <div className='login-box__body'>
                                <Form onFinish={this.onFinish} ref={ this.uformRef }>
                                    <FormItem name='username'>
                                        <Input
                                            placeholder="请输入账号"
                                            prefix={<UserOutlined className="site-form-item-icon" />}
                                        />
                                    </FormItem>
                                    <FormItem name='password'>
                                        <Input
                                            placeholder="请输入账号"
                                            prefix={<LockOutlined />}
                                        />
                                    </FormItem>
                                    <FormItem style={{borderTop:'1px solid #eee',paddingTop:'10px'}}>
                                        <a onClick={() => this.setState({activeKey:'2'},() => this.sformRef.current.resetFields())}>验证码登录</a>
                                    </FormItem>
                                    <Button type='primary' htmlType='submit'>登录</Button>
                                </Form>
                            </div>
                        </TabPane>
                        <TabPane key='2'>
                            <div className='login-box__title'>验证码登录</div>
                            <div className='login-box__body'>
                                <Form onFinish={this.onFinish1} ref={ this.sformRef } initialValues={{
                                    prefix: '86',
                                }}>
                                    <FormItem name='phone'>
                                        <Input
                                            size="large"
                                            placeholder="请输入手机号"
                                            addonBefore={prefixSelector}
                                        />
                                    </FormItem>
                                    <FormItem name='code'>
                                        <Search
                                            placeholder="请输入验证码"
                                            enterButton="获取验证码"
                                            size="large"
                                            onSearch={ this.validate }
                                            />
                                    </FormItem>
                                    <FormItem style={{borderTop:'1px solid #eee',paddingTop:'10px'}}>
                                        <a onClick={() => this.setState({activeKey:'1'},() => this.uformRef.current.resetFields())}>验证码登录</a>
                                    </FormItem>
                                    <Button type='primary' htmlType='submit'>登录</Button>
                                </Form>
                            </div>
                        </TabPane>
                    </Tabs>
                </div>
                <Modal 
                    visible={visible}
                    onCancel={() => this.setState({visible:false})}
                    onOk={ this.sendLoginMSM }
                    okText='确定'
                    cancelText='取消'>
                    <Input 
                        value={ code } 
                        onChange={ (e) => this.setState({code:e.target.value})}
                        style={{width:'150px'}}/>
                    <img src={ validateImg }/>
                </Modal>
            </div>
        )
    }
}

export default Login;