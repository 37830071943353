import React from 'react';


export default class CompanyResults extends React.Component{

    render(){

        return  <div style={{textAlign: 'center',lineHeight: '100px'}}>
            暂未开发~
        </div>
    }
}