import React, { Component } from 'react';
import  ReactDom from 'react-dom';
import { HashRouter, Route,Switch } from 'react-router-dom'
import { createHashHistory } from "history";
import Main from './managePages/Main';
import Login from './managePages/Login';

import './less/bgIndex.less';
import 'antd/dist/antd.less';
import "slick-carousel/slick/slick.less";
import "slick-carousel/slick/slick-theme.less";

const customHistory = createHashHistory();

class App extends Component {

    render() {
        return (
            <HashRouter >
                <Switch>
                    <Route exact path='/login' component={Login}/>
                    <Route path='/' component={Main}/>
                </Switch>
            </HashRouter>
            
        )
    }
}

ReactDom.render(<App history={customHistory}/>, document.getElementById('app'))
