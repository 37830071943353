import React from 'react';
import {
    Form,
    Input,
    Button,
    Row,
    Col,
    Spin
} from 'antd';

const FormItem = Form.Item;

const layout = {
    labelCol: {
      span: 3,
    },
    wrapperCol: {
      span: 12,
    },
  };

  const layout1 = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 12,
    },
  };

export default class ConcatUs extends React.Component{

    formRef = React.createRef();

    constructor(props){
        super();

        this.state = {
            content:'',
            telephone:'',
            telephone2:'',
            eMail:'',
            eMail2:'',
            companyAddress:'',
            companyAddress2:''
        }
        this.$Ajax = props.Ajax;
    }

    componentDidMount(){
        this.$Ajax('sjy/equipment/officialwebsite/queryContactUs')
            .then((res) => {

                if(res.rows[0]){

                    this.setState(res.rows[0]);

                    for (const key in res.rows[0]) {
                        if (res.rows[0].hasOwnProperty(key)) {
                            const element = res.rows[0][key];
                            this.formRef.current.setFieldsValue({[key]:element});
                        }
                    }
                }
        })
    }

    onFinish = (v) => {
        let { showTip } = this.props;

        v = Object.assign({},this.state,v);

        this.$Ajax('sjy/equipment/officialwebsite/addOrUpdateContactUs',v)
        .then((res) => {
            showTip(res.errMsg);    
        })
    }

    render(){
        let {
            content,
            telephone,
            telephone2,
            eMail,
            eMail2,
            companyAddress,
            companyAddress2
        } = this.state;
      
        return (
            <div className='concatus'>
                <Form {...layout} onFinish={this.onFinish} ref={ this.formRef }>
                                <FormItem
                                    name='content'
                                    label='文案内容'
                                    rules={[
                                        {
                                            required:true,
                                            message:'请输入文案内容'
                                        }
                                    ]}>
                                    <p className='concatus-tip'>建议文案内容不超过200字</p>
                                    <Input.TextArea value={ content } onChange={(e) => this.setState({content:e.target.value},() => this.formRef.current.setFieldsValue({ content:this.state.content}))} autoSize={{minRows:5}}/>
                                </FormItem>
                                <Row>
                                    <Col span={12}>
                                        <FormItem
                                            name='telephone'
                                            label='联系电话'
                                            {...layout1}
                                            rules={[
                                                {
                                                    required:true,
                                                    message:'请输入联系电话'
                                                }
                                            ]}>
                                            <p className='concatus-tip'>至少填写一个</p>
                                            <p className='concatus-tip'>联系电话一</p>
                                            <Input value={ telephone } onChange={(e) => this.setState({telephone:e.target.value},() => this.formRef.current.setFieldsValue({ telephone:this.state.telephone}))} placeholder='请输入联系电话'/>
                                        </FormItem>
                                    </Col>
                                    <Col span={12}>
                                        <FormItem
                                            name='telephone2'
                                        >
                                            <p className='concatus-tip'></p>
                                            <p className='concatus-tip'>联系电话二</p>
                                            <Input value={ telephone2 } onChange={(e) => this.setState({telephone2:e.target.value},() => this.formRef.current.setFieldsValue({ telephone2:this.state.telephone2}))} placeholder='请输入联系电话'/>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <FormItem
                                            name='eMail'
                                            label='技术支持'
                                            {...layout1}
                                            rules={[
                                                {
                                                    required:true,
                                                    message:'请输入技术支持'
                                                }
                                            ]}>
                                            <p className='concatus-tip'>至少填写一个</p>
                                            <p className='concatus-tip'>邮箱一</p>
                                            <Input value={ eMail } onChange={(e) => this.setState({eMail:e.target.value},() => this.formRef.current.setFieldsValue({ eMail:this.state.eMail}))} placeholder='请输入邮箱'/>
                                        </FormItem>
                                    </Col>
                                    <Col span={12}>
                                        <FormItem
                                            name='eMail2'
                                        >
                                            <p className='concatus-tip'></p>
                                            <p className='concatus-tip'>邮箱二</p>
                                            <Input value={ eMail2 } onChange={(e) => this.setState({eMail2:e.target.value},() => this.formRef.current.setFieldsValue({ eMail2:this.state.eMail2}))} placeholder='请输入邮箱'/>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <FormItem
                                            name='companyAddress'
                                            label='公司地址'
                                            {...layout1}
                                            rules={[
                                                {
                                                    required:true,
                                                    message:'请输入公司地址'
                                                }
                                            ]}>
                                            <p className='concatus-tip'>至少填写一个</p>
                                            <p className='concatus-tip'>公司地址一</p>
                                            <Input value={ companyAddress } onChange={(e) => this.setState({companyAddress:e.target.value},() => this.formRef.current.setFieldsValue({ companyAddress:this.state.companyAddress}))} placeholder='请输入地址'/>
                                        </FormItem>
                                    </Col>
                                    <Col span={12}>
                                        <FormItem
                                            name='companyAddress2'
                                        >
                                            <p className='concatus-tip'></p>
                                            <p className='concatus-tip'>公司地址二</p>
                                            <Input value={ companyAddress2 } onChange={(e) => this.setState({companyAddress2:e.target.value},() => this.formRef.current.setFieldsValue({ companyAddress2:this.state.companyAddress2}))} placeholder='请输入地址'/>
                                        </FormItem>
                                    </Col>
                                </Row>
                            <FormItem {...{ wrapperCol: {
                                    span: 12,
                                    offset:3
                                }}}>
                                <Button type='primary' htmlType='submit'>保存</Button>
                            </FormItem>
                        </Form>
            </div>
        )
    }
}