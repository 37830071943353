import React from 'react';
import {
    Form,
    Input,
    Button,
    Spin
} from 'antd';

import img from '../images/A300/1.jpg';

const FormItem = Form.Item;

const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

export default class AccountSetting extends React.Component{

    constructor(){
        super();

        this.state = {
            realName:'',  //昵称
            userName:'',  //账号
            isShow:false
        }
    }

    componentDidMount(){
        let { Ajax } = this.props;
        Ajax('sjy/sso/officialwebsite/getUserInfo').then((res) => {
            this.setState({
                realName:res.rows[0].realName,
                userName:res.rows[0].username,
                isShow:true
            })
        })
    }

    onFinish = values => {
        let { Ajax, showTip } = this.props;

        Ajax('sjy/sso/officialwebsite/updateUserInfo',values).then((res) => {
            showTip(res.errMsg);
        })
      };

    render(){
        let { userName, realName, isShow } = this.state;

        return (
            <div className='account'>
                {
                    !isShow
                        ? <Spin />
                        : <>
                            <img className='img' src={ img } alt=""/>
                            <Form { ...layout } initialValues={{userName,realName}} onFinish={this.onFinish}>
                                <FormItem
                                    label='用户昵称'
                                    name='realName'>
                                    <Input />
                                </FormItem>
                                <FormItem
                                    label='用户账号'
                                    name='userName'>
                                    <Input />
                                </FormItem>
                                <FormItem
                                    label='旧密码'
                                    name='originPassword'
                                    rules={[{ required: true,message: '请输入密码！', }]}>
                                    <Input.Password />
                                </FormItem>
                                <FormItem
                                    label='新密码'
                                    name='password'
                                    rules={[{ required: true,message: '请输入密码！', }]}>
                                    <Input.Password />
                                </FormItem>
                                <FormItem
                                    label='确认密码'
                                    name='confirmPassword'
                                    rules={[{ required: true,message: '请输入密码！', }]}>
                                    <Input.Password />
                                </FormItem>
                                <FormItem>
                                    <Button type="primary" htmlType="submit">
                                        提交
                                    </Button>
                                </FormItem>
                            </Form>
                        </>
                }
            </div>
        )
    }
}