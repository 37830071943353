import React from 'react';
import {
    Form,
    Input,
    Checkbox,
    Button,
    Select,
    Modal
} from 'antd';

import Upload from '../components/Upload';

import {
    PlusOutlined
} from '@ant-design/icons';

import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'

import Card from '../components/Card';

const FormItem = Form.Item;
const confirm = Modal.confirm;

const layout = {
    labelCol: {
      span: 3,
    },
    wrapperCol: {
      span: 6,
    },
  };

const layout1 = {
    labelCol: {
      span: 3,
    },
    wrapperCol: {
      span: 18,
    },
  };

export default class AddProduct extends React.Component{
    formRef = React.createRef();

    constructor(props){
        super();

        let msg = props.history.location.state ? props.history.location.state : null;

        
        if(msg){
            msg.productImgs = msg.imgsList.map((item) => item.id);
        }
       
        this.state = {
            describe: msg ? BraftEditor.createEditorState(msg.description) : BraftEditor.createEditorState(null),
            productDetail:msg ? BraftEditor.createEditorState(msg.productDetail) : BraftEditor.createEditorState(null),
            productCover:msg ? msg.productCover : '',
            productImgs:msg&&msg.imgsList ? msg.imgsList : [],
            isAll:false,
            checkedAllList:msg&&msg.productImgs ? msg.productImgs : [],
            checkedList:[],
            initValue: msg || {}
        }

        this.$Ajax = props.Ajax;
    }
    
    handleEditorChange = (editorState) => {
        this.setState({ editorState })
    }

    onSwitchChange = (imageId,v,index) => {
        let { productImgs } = this.state;
        let { showTip } = this.props;

        this.$Ajax('sjy/equipment/officialwebsite/enableMainPageImage',{imageId})
            .then((res) => {
               showTip('修改状态成功');

               productImgs[index].status = v ? 1 : 0;
                this.setState({
                    productImgs
                })
            })
    }

    onCheckboxChange = (e) => {
        let { checkedList } = this.state;
        let v = parseInt(e.target.value);

        if(e.target.checked){
            this.setState({checkedList: checkedList.concat(v)});
        }else{
            let index = checkedList.indexOf(v);

            checkedList.splice(index,1);
            this.setState({checkedList});
        }
    }

    deleteImg = () => {
        let { checkedList, productImgs } = this.state;
        let { showTip } = this.props;
        confirm({
            title:'是否删除？',
            okText:'确认',
            cancelText:'取消',
            onOk:() => {
                this.$Ajax('sjy/equipment/officialwebsite/deleteMainPageImages',{imageIds: checkedList.join(',')})
                    .then((res) => {
                        showTip('删除成功');

                        let list = productImgs.filter((item) => !checkedList.includes(item.id));
                        let checkedAllList = list.map((item) => {
                            return item.id;
                        })
                        this.setState({
                            checkedList:[],
                            isAll:false,
                            productImgs:list,
                            checkedAllList
                        })
                        
                    })
            },
            onCancel(){}
        })
    }

    onFinish = (v) => {
        let { initValue } = this.state;
        let { showTip } = this.props;
        v.productImgs = v.productImgs.join(',');
        v = Object.assign(initValue,v);

        delete v.imgsList;

        let url = initValue.type == 2 ? 'sjy/equipment/officialwebsite/updateProduct' : 'sjy/equipment/officialwebsite/addProduct';

        this.$Ajax(url,v)
        .then((res) => {
            showTip(res.errMsg);
          
            this.props.history.push('/ProductCenter');
            
        })
    }

    uploadFn = (params) => {
        this.$Ajax('sjy/equipment/officialwebsite/uploadMainPageImage',
            {
                imageType:1,
                imageName:'43234',
                file:params.file,
                isFormData: true
            }
        ).then((res) => {
           
            params.success({
                url: res.rows,
                meta: {
                  id: res.data,
                  title: 'img',
                  alt: 'img',
                },
            });
        })
    }

    render(){

        let { 
            describe, 
            productCover, 
            productDetail, 
            productImgs,
            checkedAllList,
            checkedList,
            isAll,
            initValue
        } = this.state;
        let { showTip } = this.props;

        const uploadButton = (
            <div style={{textAlign:'center'}}>
               <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        );

        return <div className='addproduct'>
            <Form {...layout} onFinish={this.onFinish} ref={ this.formRef } initialValues={initValue}>
                <FormItem
                    name='name'
                    label='产品名称'
                    rules={[
                        {
                            required:true,
                            message:'请输入产品名称'
                        }
                    ]}>
                    <Input />
                </FormItem>
                <FormItem
                    name='websiteClass'
                    label='产品分类'
                    rules={[
                        {
                            required:true,
                            message:'请选择产品分类'
                        }
                    ]}>
                    <Select>
                        <Option value='打印设备'>打印设备</Option>
                        <Option value='打印耗材'>打印耗材</Option>
                        <Option value='软件中心'>软件中心</Option>
                    </Select>
                </FormItem>
                <FormItem
                    name='productAbstract'
                    label='产品摘要'
                    rules={[
                        {
                            required:true,
                            message:'请输入产品摘要'
                        }
                    ]}>
                    <Input.TextArea autoSize={{minRows:5}}/>
                </FormItem>
                <FormItem
                    name='description'
                    label='产品介绍'
                    rules={[
                        {
                            required:true,
                            message:'请输入产品介绍'
                        }
                    ]}
                    {...layout1}>
                     <BraftEditor
                        defaultValue={describe}
                        value={describe}
                        onChange={(describe) => {this.setState({describe}); this.formRef.current.setFieldsValue({ description:describe.isEmpty() ? null : describe.toHTML() });}}
                        />
                </FormItem>
                <FormItem
                    name='productCover'
                    label='产品封面'
                    className="avatar-uploader"
                    rules={[
                        {
                            required:true,
                            message:'请上传产品封面'
                        }
                    ]}>
                    <Upload
                        otherProps={{
                            data:{
                                imageType:1,
                                imageName:'43234'
                            },
                            name: 'file',
                            showUploadList:false,
                            onChange:(info) => {
                                if (info.file.status !== 'uploading') {
                                  console.log(info.file, info.fileList);
                                }
                                if (info.file.status === 'done') {
                                    showTip('上传成功')
                                    this.setState({
                                        productCover:info.file.response.rows
                                    })
                                    this.formRef.current.setFieldsValue({ productCover:info.file.response.rows });
                                } else if (info.file.status === 'error') {
                                  showTip('上传失败')
                                }
                            },                            
                        }}
                        url='sjy/equipment/officialwebsite/uploadMainPageImage'
                    >
                        {productCover ? <img src={productCover} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                    </Upload>
                </FormItem>
                <FormItem
                    name='productImgs'
                    label='产品图片'
                    rules={[
                        {
                            required:true,
                            message:'请上传产品图片'
                        }
                    ]}
                    {...layout1}>
                   <p className='addproduct-imgs'>
                       <span className='addproduct-imgs__tip'>建议图片比例为 1:2.5(500 * 1250像素)，支持png、jpg格式，小于500k</span>
                       <div className='addproduct-imgs__action'>
                            <Checkbox checked={ isAll } onChange={() => this.setState({isAll:!isAll,checkedList:checkedList.length == checkedAllList.length ? [] : checkedAllList})}/>全选
                            <Button onClick={ this.deleteImg }>删除</Button>  
                       </div>
                        
                       <Upload
                            otherProps={{
                                data:{
                                    imageType:1,
                                    imageName:'43234'
                                },
                                name: 'file',
                                showUploadList:false,
                                onChange:(info) => {
                                    if (info.file.status !== 'uploading') {
                                    console.log(info.file, info.fileList);
                                    }
                                    if (info.file.status === 'done') {
                                        showTip('上传成功');
                                        
                                        this.setState({
                                            productImgs:productImgs.concat({url:info.file.response.rows,id:info.file.response.data,status:0}),
                                            checkedAllList:checkedAllList.concat(info.file.response.data)
                                        })
                                        
                                        this.formRef.current.setFieldsValue({ productImgs:checkedAllList.concat(info.file.response.data) });
                                    } else if (info.file.status === 'error') {
                                        showTip('上传失败')
                                    }
                                },                            
                            }}
                            url='sjy/equipment/officialwebsite/uploadMainPageImage'
                        >
                            <Button>上传图片</Button>
                        </Upload>
                    </p>
                    <div style={{display:'flex',flexWrap:'wrap'}}>
                        {
                            productImgs.map((item,index) => {
                                return <Card 
                                    value={item.id}
                                    checked={ checkedList.indexOf(item.id) != -1 ? true : false }
                                    name={'产品' + index}
                                    isShow={item.status ? true : false}
                                    onSwitchChange={(v) => this.onSwitchChange(item.id,v,index)}
                                    onCheckboxChange={ this.onCheckboxChange }
                                    img={item.url}/>
                            })
                        }
                       
                    </div>
                </FormItem>
                <FormItem
                    name='productDetail'
                    label='产品详情'
                    rules={[
                        {
                            required:true,
                            message:'请输入产品详情'
                        }
                    ]}
                    {...layout1}>
                        <BraftEditor
                            media={{uploadFn:this.uploadFn,image:true}}
                            defaultValue={productDetail}
                            value={productDetail}
                            onChange={(productDetail) => {console.log(111);this.setState({productDetail}),this.formRef.current.setFieldsValue({ productDetail:productDetail.isEmpty() ? null : productDetail.toHTML()});}}
                        />
                </FormItem>
                <FormItem {...{
                    wrapperCol: {
                        span: 18,
                        offset:3
                    },
                }}>
                    {
                        initValue.type == 1 
                            ? ''
                            :  <Button type='primary' htmlType='submit'>
                            提交
                        </Button>
                    }
                </FormItem>
               
            </Form>
            
        </div>
    }
}