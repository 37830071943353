import React from 'react';
import {
    Upload as AntdUpload
} from 'antd';

import { getToken } from '../utils/Ajax';

//const prefix = 'http://192.168.31.83:8085/';
//const prefix = 'http://1.openapi.sjyprt.com/SjyCloudManage-equipment-web/';
const prefix = 'https://openapi.sjychina.com/SjyCloudManage-equipment-web/';

export default class Upload extends React.Component{
    render(){
        let { url, otherProps } = this.props;

        otherProps.data.token = getToken();

        return <AntdUpload
                action={prefix + url}
                {...otherProps}
            >
            { this.props.children }
        </AntdUpload>
    }
}