import React from 'react';
import {
    Form,
    Input,
    Button,
    Modal
} from 'antd';

import Upload from '../components/Upload';

import {
    PlusOutlined
} from '@ant-design/icons';

import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'

const FormItem = Form.Item;
const confirm = Modal.confirm;

const layout = {
    labelCol: {
      span: 3,
    },
    wrapperCol: {
      span: 6,
    },
  };

const layout1 = {
    labelCol: {
      span: 3,
    },
    wrapperCol: {
      span: 18,
    },
  };

export default class AddScheme extends React.Component{
    formRef = React.createRef();

    constructor(props){
        super();

        let msg = props.history.location.state ? props.history.location.state : null;
       
        this.state = {
            describe: msg ? BraftEditor.createEditorState(msg.detail) : BraftEditor.createEditorState(null),
            articleCoverImg:msg ? msg.articleCoverImg : '',
            initValue: msg || {},
            backgroundImg:msg ? msg.articleCoverImg : '',
        }

        this.$Ajax = props.Ajax;
    }

    onFinish = (v) => {
        let { initValue } = this.state;
        let { showTip } = this.props;
        v = Object.assign(initValue,v);

        let url = initValue.type == 2 ? 'sjy/equipment/officialwebsite/updateArticle' : 'sjy/equipment/officialwebsite/addArticle';

        this.$Ajax(url,v)
        .then((res) => {
            showTip(res.errMsg);
          
            this.props.history.push('/SchemeList');
            
        })
    }
   

    render(){

        let { 
            describe, 
            articleCoverImg, 
            backgroundImg,
            initValue
        } = this.state;
        let { showTip } = this.props;

        const uploadButton = (
            <div style={{textAlign:'center'}}>
               <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        );

        return <div className='addproduct'>
            <Form {...layout} onFinish={this.onFinish} ref={ this.formRef } initialValues={initValue}>
                <FormItem
                    name='title'
                    label='标题'
                    rules={[
                        {
                            required:true,
                            message:'请输入标题'
                        }
                    ]}>
                    <Input />
                </FormItem>
                <FormItem
                    name='articleAbstract'
                    label='文章摘要'
                    rules={[
                        {
                            required:true,
                            message:'请输入文章摘要'
                        }
                    ]}>
                    <Input.TextArea autoSize={{minRows:5}}/>
                </FormItem>
                <FormItem
                    name='detail'
                    label='文章详情'
                    rules={[
                        {
                            required:true,
                            message:'请输入文章详情'
                        }
                    ]}
                    {...layout1}>
                     <BraftEditor
                        defaultValue={describe}
                        value={describe}
                        onChange={(describe) => {this.setState({describe}); this.formRef.current.setFieldsValue({ detail:describe.isEmpty() ? null : describe.toHTML() });}}
                        />
                </FormItem>
                <FormItem
                    name='articleCoverImg'
                    label='文章封面'
                    className="avatar-uploader"
                    rules={[
                        {
                            required:true,
                            message:'请上传文章封面'
                        }
                    ]}>
                    <Upload
                        otherProps={{
                            data:{
                                imageType:1,
                                imageName:'43234'
                            },
                            name: 'file',
                            showUploadList:false,
                            onChange:(info) => {
                                if (info.file.status !== 'uploading') {
                                  console.log(info.file, info.fileList);
                                }
                                if (info.file.status === 'done') {
                                    showTip('上传成功')
                                    this.setState({
                                        articleCoverImg:info.file.response.rows
                                    })
                                    this.formRef.current.setFieldsValue({ articleCoverImg:info.file.response.rows });
                                } else if (info.file.status === 'error') {
                                  showTip('上传失败')
                                }
                            },                            
                        }}
                        url='sjy/equipment/officialwebsite/uploadMainPageImage'
                    >
                        {articleCoverImg ? <img src={articleCoverImg} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                    </Upload>
                </FormItem>
                {/* <FormItem
                        className="avatar-uploader"
                        label='背景图片'
                        name='backgroundImg'
                        rules={[
                            {
                                required:true,
                                message:'请上传背景图片'
                            }
                        ]}
                    >
                        <Upload
                        otherProps={{
                            data:{
                                imageType:1,
                                imageName:'43234'
                            },
                            name: 'file',
                            showUploadList:false,
                            onChange:(info) => {
                                if (info.file.status !== 'uploading') {
                                  console.log(info.file, info.fileList);
                                }
                                if (info.file.status === 'done') {
                                    showTip('上传成功')
                                    this.setState({
                                        backgroundImg:info.file.response.rows
                                    })
                                    this.formRef.current.setFieldsValue({ backgroundImg:info.file.response.rows });
                                } else if (info.file.status === 'error') {
                                  showTip('上传失败')
                                }
                            },                            
                        }}
                        url='sjy/equipment/officialwebsite/uploadMainPageImage'
                    >
                        {backgroundImg ? <img src={backgroundImg} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                    </Upload>
                    </FormItem> */}
                <FormItem {...{
                    wrapperCol: {
                        span: 18,
                        offset:3
                    },
                }}>
                    {
                        initValue.type == 1 
                            ? ''
                            :  <Button type='primary' htmlType='submit'>
                            提交
                        </Button>
                    }
                </FormItem>
               
            </Form>
            
        </div>
    }
}