import React from 'react';
import {
    Checkbox,
    Switch,
    Modal
} from 'antd';

export default class Card extends React.Component{

    state = {
        privewImg:''
    }


    render(){
        let { privewImg } = this.state;

        let {
            value,
            checked,
            name,
            isShow,
            onSwitchChange,
            onCheckboxChange,
            img
        } = this.props;
        return (
            <div className='card'>
                <div style={{height:'157px',overflow:'hidden'}}>
                    <img className='card-img' src={ img } alt="" onClick={ () => this.setState({privewImg:img}) }/>
                </div>
                <div className='card-footer'>
                    <Checkbox value={ value } checked={ checked } onChange={ onCheckboxChange }/>
                    <span>{ name }</span>
                    <Switch checked={ isShow } onChange={ onSwitchChange }/>
                </div>

                <Modal 
                    className='privew-img'
                    visible={ privewImg ? true : false }
                    onCancel={ () => this.setState({privewImg:''}) }
                    footer={ false }>
                        <img style={{maxWidth:"100%"}} src={privewImg}/>
                </Modal>
            </div>
        )
    }
}