import React from 'react';
import {
    Form,
    Input,
    Button,
    Spin
} from 'antd';


import Upload from '../components/Upload';

import {
    PlusOutlined
} from '@ant-design/icons';

const FormItem = Form.Item;

const layout = {
    labelCol: {
      span: 3,
    },
    wrapperCol: {
      span: 10,
    },
  };

export default class CompanyIntroduce extends React.Component{

    formRef = React.createRef();

    constructor(props){
        super();

        this.state = {
            imageUrl:'',
            historyImg:'',
            initValue:{},
            isShow:false
        }

        this.$Ajax = props.Ajax;
    }

    componentDidMount(){
        this.$Ajax('sjy/equipment/officialwebsite/selectCompanyInfo')
            .then((res) => {
            this.setState({
                initValue: res.rows[0] || {},
                imageUrl: res.rows[0] ? res.rows[0].backgroundImg : '',
                historyImg: res.rows[0] ? res.rows[0].historyImg : '',
                isShow:true
            })
        })
    }

    onFinish = (v) => {
        let { initValue } = this.state;
        let { showTip } = this.props;
        v = Object.assign(initValue,v);

        this.$Ajax('sjy/equipment/officialwebsite/addOrUpdateCompany',v)
        .then((res) => {
            showTip(res.errMsg);
        })
    }

    render(){

        let { showTip } = this.props;
        let { imageUrl, historyImg, initValue, isShow } = this.state;

        const uploadButton = (
            <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          );

        return (
            <div className='company'>
                {
                    !isShow
                        ? <Spin />
                        : <Form { ...layout } onFinish={this.onFinish} initialValues={initValue} ref={this.formRef}>
                        <FormItem
                            label='背景图片'
                            name='backgroundImg'
                            rules={[
                                {
                                    required:true,
                                    message:'请上传背景图片'
                                }
                            ]}
                        >
                            <Upload
                                url='sjy/equipment/officialwebsite/uploadMainPageImage'
                                otherProps={{
                                    name:"file",
                                    listType:"picture-card",
                                    showUploadList:false,
                                    data:{
                                        imageType:2,
                                        imageName:'43234'
                                    },

                                    onChange:(info) => {
                                        if (info.file.status !== 'uploading') {
                                        console.log(info.file, info.fileList);
                                        }
                                        if (info.file.status === 'done') {
                                            showTip('上传成功')
                                            this.setState({
                                                imageUrl:info.file.response.rows
                                            })
                                            this.formRef.current.setFieldsValue({ backgroundImg:info.file.response.rows });
                                        } else if (info.file.status === 'error') {
                                        showTip('上传失败')
                                        }
                                    }
                                }}
                            >
                                {imageUrl ? <img src={imageUrl} alt="avatar" style={{ height: '100%' }} /> : uploadButton}
                            </Upload>
                        </FormItem>
                        <FormItem
                            label='公司业务'
                            name='business'
                            rules={[
                                {
                                    required:true,
                                    message:'请输入公司业务'
                                }
                            ]}>
                            <Input.TextArea autoSize={{ minRows: 5, maxRows: 10 }}/>
                        </FormItem>
                        <FormItem
                            label='公司使命'
                            name='mission'
                            rules={[
                                {
                                    required:true,
                                    message:'请输入公司使命'
                                }
                            ]}>
                            <Input.TextArea autoSize={{ minRows: 5, maxRows: 10 }}/>
                        </FormItem>
                        <FormItem
                            label='公司愿景'
                            name='future'
                            rules={[
                                {
                                    required:true,
                                    message:'请输入公司愿景'
                                }
                            ]}>
                            <Input.TextArea autoSize={{ minRows: 5, maxRows: 10 }}/>
                        </FormItem>
                        <FormItem
                            label='发展历程'
                            name='historyImg'
                            rules={[
                                {
                                    required:true,
                                    message:'请上传发展历程'
                                }
                            ]}
                        >
                            <Upload
                                url='sjy/equipment/officialwebsite/uploadMainPageImage'
                                otherProps={{
                                    name:"file",
                                    listType:"picture-card",
                                    showUploadList:false,
                                    data:{
                                        imageType:1,
                                        imageName:'43234'
                                    },
    
                                    onChange:(info) => {
                                        if (info.file.status !== 'uploading') {
                                        console.log(info.file, info.fileList);
                                        }
                                        if (info.file.status === 'done') {
                                            showTip('上传成功')
                                            this.setState({
                                                historyImg:info.file.response.rows
                                            })
                                            this.formRef.current.setFieldsValue({ historyImg:info.file.response.rows });
                                        } else if (info.file.status === 'error') {
                                        showTip('上传失败')
                                        }
                                    }
                                }}
                            >
                                {historyImg ? <img src={historyImg} alt="avatar" style={{ height: '100%' }} /> : uploadButton}
                            </Upload>
                        </FormItem>
                        <FormItem wrapperCol={{ span: 10, offset: 3 }}>
                            <Button type='primary' htmlType='submit'>保存</Button>
                        </FormItem>
                    </Form>
                }   
            </div>
        )
    }
}