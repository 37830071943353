import React from 'react';
import {
    Button,
    Checkbox,
    Modal,
    Pagination
} from 'antd';
import Card from '../components/Card';
import Upload from '../components/Upload';
import local from 'antd/lib/locale/zh_CN';

const confirm = Modal.confirm;

export default class Carousel extends React.Component{

    constructor(props){
        super();
        this.state = {
            isAll:false,
            list:[],
            total:0,
            pageCheckedList:[],
            checkedList:[],
            pageNum:1,
            pageSize:10
        }

        this.$Ajax = props.Ajax;
    }

    componentDidMount(){
        this.getImgsList(1);
    }

    getImgsList = (pageNum) => {
   
        let { pageSize } = this.state;

        this.$Ajax('sjy/equipment/officialwebsite/getMainPageImageInfoList',{pageNum,pageSize})
            .then((res) => {

                let pageCheckedList = res.rows.map((item) => {
                    return item.id;
                })

                this.setState({
                    list:res.rows,
                    total:res.total,
                    pageCheckedList,
                    pageNum
                })
            })
    }

    deleteImg = () => {
        let { checkedList, pageNum } = this.state;
        let { showTip } = this.props;
        confirm({
            title:'是否删除？',
            okText:'确认',
            cancelText:'取消',
            onOk:() => {
                this.$Ajax('sjy/equipment/officialwebsite/deleteMainPageImages',{imageIds: checkedList.join(',')})
                    .then((res) => {
                        showTip('删除成功');
                        this.setState({
                            checkedList:[],
                            isAll:false
                        },() => {
                            this.getImgsList(pageNum);
                        })
                        
                    })
            },
            onCancel(){}
        })
    }

    onShowSizeChange = (current, pageSize) => {

        this.setState({
            pageSize
        },() => {
            this.getImgsList(1);
        })
    }

    onSwitchChange = (imageId) => {
        let { pageNum } = this.state;
        let { showTip } = this.props;

        this.$Ajax('sjy/equipment/officialwebsite/enableMainPageImage',{imageId})
            .then((res) => {
               showTip('修改状态成功');
               this.getImgsList(pageNum);
            })
    }

    onCheckboxChange = (e) => {
        let { checkedList } = this.state;
        let v = parseInt(e.target.value);

        if(e.target.checked){
            this.setState({checkedList: checkedList.concat(v)});
        }else{
            let index = checkedList.indexOf(v);

            checkedList.splice(index,1);
            this.setState({checkedList});
        }
    }

    render(){
        let { 
            isAll, 
            list, 
            total, 
            pageCheckedList,
            checkedList,
            pageNum
        } = this.state;

        let { showTip } = this.props;

        return (
            <div className='carousel'>
                <div className='carousel-title'>
                    <div className='carousel-title__left'>
                        <span>图片（{total}）</span>
                        <span>建议图片比例为 2.5：1(1250 * 500像素)，支持png、jpg格式，小于500k</span>
                    </div>
                    <Upload
                        otherProps={{
                            data:{
                                imageType:0,
                                imageName:'43234'
                            },
                            name: 'file',
                            showUploadList:false,
                            onChange:(info) => {
                                if (info.file.status !== 'uploading') {
                                  console.log(info.file, info.fileList);
                                }
                                if (info.file.status === 'done') {
                                    showTip('上传成功')
                                    this.getImgsList(1);
                                } else if (info.file.status === 'error') {
                                  showTip('上传失败')
                                }
                            },                            
                        }}
                        url='sjy/equipment/officialwebsite/uploadMainPageImage'
                    >
                        <Button>上传图片</Button>
                    </Upload>
                    
                </div>
                <div className='carousel-body'>
                    {
                        list.map((item,index) => {
                            return <Card 
                                key={index}
                                value={item.id}
                                checked={ checkedList.indexOf(item.id) != -1 ? true : false }
                                name={'轮播'+index}
                                isShow={ item.status ? true : false }
                                onSwitchChange={(v) => this.onSwitchChange(item.id,v)}
                                onCheckboxChange={ this.onCheckboxChange }
                                img={item.url}/>
                        })
                    }
                </div>
                <div className='carousel-footer'>
                    <div className='carousel-footer__left'>
                        <Checkbox checked={ isAll } onChange={() => this.setState({isAll:!isAll,checkedList:checkedList.length == pageCheckedList.length ? [] : pageCheckedList})}/>
                        <Button onClick={ this.deleteImg }>删除</Button>
                    </div>
                    <Pagination
                        showSizeChanger
                        onShowSizeChange={this.onShowSizeChange}
                        total={total}
                        current={pageNum}
                        locale={local.Pagination}
                        onChange={ this.getImgsList }
                    />
                </div>
            </div>
        )
    }
}