import React from 'react';
import {
    Button,
    Input,
    Switch,
    Table,
    Dropdown,
    Menu,
    Modal
} from 'antd';

import {
    SearchOutlined
} from '@ant-design/icons'

const confirm = Modal.confirm;

export default class SchemeList extends React.Component{

    constructor(props){
        super();
        this.state = {
            list:[],
            total:0,
            name:'',
            selectedRowKeys:[]
        }

        this.$Ajax = props.Ajax;
    }

    componentDidMount(){

        this.getProductList(1);
    }

    getProductList = (pageNum) => {
        let { name } = this.state;

        this.$Ajax('sjy/equipment/officialwebsite/selectArticleList',{pageSize:10,pageNum,name})
            .then((res) => {
              this.setState({
                  list:res.rows,
                  total:res.total
              })
        })
    }


    delete = (id) => {
        let { selectedRowKeys, pageNum } = this.state;
        let { showTip } = this.props;

        selectedRowKeys = id ? [id] : selectedRowKeys;

        confirm({
            title:'是否删除？',
            okText:'确认',
            cancelText:'取消',
            onOk:() => {
                this.$Ajax('sjy/equipment/officialwebsite/deleteArticle',{ids: selectedRowKeys.join(',')})
                    .then((res) => {
                        showTip('删除成功');
                        this.setState({
                            selectedRowKeys:[]
                        },() => {
                            this.getProductList(pageNum);
                        })
                        
                    })
            },
            onCancel(){}
        })
    }

    onChange = (v,rows,key) => {
        let { showTip } = this.props;
        let { pageNum } = this.state;
        rows[key] = v ? 1 : 0;

        this.$Ajax('sjy/equipment/officialwebsite/updateArticle',rows)
            .then((res) => {
                showTip(res.errMsg);
                this.setState({
                    selectedRowKeys:[]
                },() => {
                    this.getProductList(pageNum);
                })
                
            })
    }

    selectedRowKeys = (list) => {
        
        return list.map((item) => {
            return item.id;
        })
    }

    render(){
        let { list, total, selectedRowKeys, name } = this.state;

        const columns = [
            {
                title: '标题',
                dataIndex: 'title',
            },
            {
                title: '发布时间',
                dataIndex: 'publicTimeString',
            },
            {
                title: '是否启用',
                dataIndex: 'status',
                render:(text,record) => {
                    return <Switch checked={ text ? true : false } onChange={(e) => this.onChange(e,record,'status')}/>
                }
            },
            {
                title: '首页展示',
                dataIndex: 'type',
                render:(text,record) => {
                    return <Switch checked={ text ? true : false } onChange={(e) => this.onChange(e,record,'type')}/>
                }
            },
            {
                title: '操作',
                dataIndex: 'age',
                render:(text,record) => {
                    return <>
                        <a className='margin-15px' onClick={ () => this.props.history.push({pathname:'/AddScheme',state:Object.assign(record,{type:1})})}>查看</a>
                        <a className='margin-15px' onClick={ () => this.props.history.push({pathname:'/AddScheme',state:Object.assign(record,{type:2})})}>编辑</a>
                        <a onClick={() => this.delete(record.id)}>删除</a>
                    </>
                }
            },
        ];

        const pagination = {
            total,
            current:1,
            pageSize:10,
            showQuickJumper:true,
            onChange:this.getProductList
        };

        const menu = (
            <Menu>
              <Menu.Item>
                <a href="javascript:void(0)" onClick={ this.delete }>
                  删除
                </a>
              </Menu.Item>
            </Menu>
          );          

        return (
            <div className='product'>
                <div className='product-title'>
                    <Button type='primary' onClick={() => this.props.history.push('/AddScheme')}>添加</Button>
                    <div className='product-title__right'>
                        <Input value={ name } onChange={(e) => { this.setState({name:e.target.value}) }} addonAfter={<SearchOutlined onClick={() => this.getProductList(1)}/>}/>
                    </div>
                </div>
                <Table 
                    columns={ columns }
                    dataSource={ list }
                    pagination={ pagination }
                    rowKey='id'
                    rowSelection={{
                        selectedRowKeys,
                        onSelect:(selectItem,selected,selectedList) => this.setState({selectedRowKeys:this.selectedRowKeys(selectedList)}),
                        onSelectAll:(selected,selectedList,selectItems) => this.setState({selectedRowKeys:this.selectedRowKeys(selectedList)})
                    }}
                />
                <div className='product-batch'>
                    <Dropdown overlay={menu} placement="bottomLeft">
                        <Button>批量操作</Button>
                    </Dropdown>
                </div>
               
            </div>
        )
    }
}