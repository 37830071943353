import React from 'react';
import {
    Button,
    Table,
    Menu,
    Dropdown,
    Modal,
    Form,
    Input,
    Switch
} from 'antd';

import Upload from '../components/Upload';

const FormItem = Form.Item;
const confirm = Modal.confirm;

import {
    PlusOutlined,
    FolderOutlined
} from '@ant-design/icons';

const uploadButton = (
    <div style={{textAlign:'center'}}>
       <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
    </div>
);

const successButton = (
    <div style={{textAlign:'center'}}>
       <FolderOutlined style={{fontSize:40}}/>
        <div style={{ marginTop: 8 }}>上传成功</div>
    </div>
);


const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 12,
    },
  };

export default class SoftwareCenter extends React.Component{
    formRef = React.createRef();

    constructor(props){
        super();

        this.state = {
            list:[],
            nav:0,
            total:0,
            isShow:false,
            isShow1:false,
            softwarePackage:'',
            initValue:{},
            selectedRowKeys:[],
            size:'',
            actionType:2
        }

        this.$Ajax = props.Ajax;
    }

    addOrUpdaterModal = () => {
        let { initValue, softwarePackage, actionType } = this.state;
        let { showTip } = this.props;
        return <Modal
            visible={ true }
            footer={false}
            onCancel={() => this.setState({isShow:false})}
            title='添加/修改软件'
            >
            <Form {...layout} onFinish={(v) => this.onFinish(v,0)} ref={ this.formRef } initialValues={initValue}>
                <FormItem
                    name='name'
                    label='名称'
                    rules={[
                        {
                            required:true,
                            message:'请输入名称'
                        }
                    ]}>
                    <Input />
                </FormItem>
                
                <FormItem
                    name='softwarePackage'
                    label='上传软件'
                    className="avatar-uploader"
                    rules={[
                        {
                            required:true,
                            message:'请上传软件'
                        }
                    ]}>
                    <Upload
                        otherProps={{
                            data:{},
                            name: 'file',
                            showUploadList:false,
                            onChange:(info) => {
                                if (info.file.status !== 'uploading') {
                                  console.log(info.file, info.fileList);
                                }
                                if (info.file.status === 'done') {
                                    showTip('上传成功')
                                    this.setState({
                                        softwarePackage:info.file.response.rows
                                    })
                                    this.formRef.current.setFieldsValue({ softwarePackage:info.file.response.rows });
                                } else if (info.file.status === 'error') {
                                  showTip('上传失败')
                                }
                            },                            
                        }}
                        url='sjy/equipment/officialwebsite/uploadSoftware'
                    >
                        {softwarePackage ? successButton : uploadButton}
                    </Upload>
                </FormItem>
                {
                    actionType == 1
                    ? ''
                    : <FormItem style={{ display: 'flex',justifyContent: 'flex-end'}} {...{
                        wrapperCol: {
                            span: 8,
                            offset:16
                        },
                    }}>
                        <Button onClick={() => this.setState({isShow:false})} style={{marginRight:'10px'}}>
                            取消
                        </Button>
                        <Button type='primary' htmlType='submit' >
                            保存
                        </Button>
                    </FormItem>
                }
                
               
            </Form>
        </Modal>
    }

    addOrUpdateqModal = () => {
        let { initValue, softwarePackage, size, actionType } = this.state;
        let { showTip } = this.props;
        return <Modal
            visible={ true }
            footer={false}
            onCancel={() => this.setState({isShow1:false})}
            title='添加/修改驱动'
            >
            <Form {...layout} onFinish={(v) => this.onFinish(v,1)} ref={ this.formRef } initialValues={initValue}>
                <FormItem
                    name='name'
                    label='打印机名称'
                    rules={[
                        {
                            required:true,
                            message:'请输入打印机名称'
                        }
                    ]}>
                    <Input />
                </FormItem>
                <FormItem
                    name='description'
                    label='描述'
                    rules={[
                        {
                            required:true,
                            message:'请输入描述'
                        }
                    ]}>
                    <Input.TextArea autoSize={{minRows:5}}/>
                </FormItem>
                <FormItem
                    name='system'
                    label='系统'
                    rules={[
                        {
                            required:true,
                            message:'请输入系统'
                        }
                    ]}>
                    <Input />
                </FormItem>
                <FormItem
                    name='version'
                    label='版本'
                    rules={[
                        {
                            required:true,
                            message:'请输入版本'
                        }
                    ]}>
                    <Input />
                </FormItem>
                <FormItem
                    name='softwarePackage'
                    label='固件上传'
                    className="avatar-uploader"
                    rules={[
                        {
                            required:true,
                            message:'请上传固件'
                        }
                    ]}>
                    <Upload
                        otherProps={{
                            data:{},
                            name: 'file',
                            showUploadList:false,
                            onChange:(info) => {
                                if (info.file.status !== 'uploading') {
                                  console.log(info.file, info.fileList);
                                }
                                if (info.file.status === 'done') {
                                    showTip('上传成功')
                                    this.setState({
                                        softwarePackage:info.file.response.rows,
                                        size:info.file.response.data
                                    })
                                    this.formRef.current.setFieldsValue({ softwarePackage:info.file.response.rows });
                                } else if (info.file.status === 'error') {
                                  showTip('上传失败')
                                }
                            },                            
                        }}
                        url='sjy/equipment/officialwebsite/uploadSoftware'
                    >
                        {softwarePackage ? successButton : uploadButton}
                    </Upload>
                    <div>
                        { size }
                    </div>
                </FormItem>
               {
                   actionType == 1
                    ? ''
                    : <FormItem style={{ display: 'flex',justifyContent: 'flex-end'}} {...{
                        wrapperCol: {
                            span: 8,
                            offset:16
                        },
                    }}>
                        <Button onClick={() => this.setState({isShow1:false})} style={{marginRight:'10px'}}>
                            取消
                        </Button>
                        <Button type='primary' htmlType='submit' >
                            保存
                        </Button>
                    </FormItem>
               }
                
               
            </Form>
        </Modal>
    }

    componentDidMount(){
       this.selectSoftwareList(1);
    }

    selectSoftwareList = (pageNum) => {
        let { name, nav } = this.state;

        this.$Ajax('sjy/equipment/officialwebsite/selectSoftwareList',{pageSize:10,pageNum,name,type:nav})
            .then((res) => {
              this.setState({
                  list:res.rows,
                  total:res.total
              })
        })
    }

    onChange = (nav) => {
        this.setState({
            nav,
            selectedRowKeys:[]
        },() => this.selectSoftwareList(1))
    }


    onSwitchChange = (v,rows,key) => {
        let { showTip } = this.props;
        let { pageNum } = this.state;
        rows[key] = v ? 1 : 0;

        this.$Ajax('sjy/equipment/officialwebsite/updateSoftware',rows)
            .then((res) => {
                showTip(res.errMsg);
                this.selectSoftwareList(pageNum);
            })
    }

    delete = (id) => {
        let { selectedRowKeys, pageNum } = this.state;
        let { showTip } = this.props;

        selectedRowKeys = id ? [id] : selectedRowKeys;

        console.log(selectedRowKeys);
        confirm({
            title:'是否删除？',
            okText:'确认',
            cancelText:'取消',
            onOk:() => {
                this.$Ajax('sjy/equipment/officialwebsite/deleteSoftware',{ids: selectedRowKeys.join(',')})
                    .then((res) => {
                        showTip('删除成功');
                        this.setState({
                            selectedRowKeys:[]
                        },() => {
                            this.selectSoftwareList(pageNum);
                        })
                        
                    })
            },
            onCancel(){}
        })
    }

    onFinish = (v,type) => {
        let { initValue,size } = this.state;
        let { showTip } = this.props;

        v = Object.assign(initValue,v,{type,size});

        let url = v.id ? 'sjy/equipment/officialwebsite/updateSoftware' : 'sjy/equipment/officialwebsite/addSoftware';

        this.$Ajax(url,v)
        .then((res) => {
            showTip(res.errMsg);
          
           this.setState({
               isShow:false,
               isShow1:false
           })

           this.selectSoftwareList(1);
            
        })
    } 
    
    selectedRowKeys = (list) => {
  
        return list.map((item) => {
            return item.id;
        })
    }

    render(){
        let { list, nav, total, isShow, isShow1, selectedRowKeys } = this.state;

        const columns = [
            {
                title:'名称',
                dataIndex:'name'
            },
            {
                title:'是否启用',
                dataIndex:'status',
                render:(text,record) => {
                    return <Switch checked={ text ? true : false } onChange={(e) => this.onSwitchChange(e,record,'status')}/>
                }
            },
            {
                title:'操作',
                dataIndex:'action',
                render:(text,record) => {
                    return <>
                        <a className='margin-15px' onClick={ () => this.setState({actionType:1,initValue:record,isShow:true,softwarePackage:record.softwarePackage})}>查看</a>
                        <a className='margin-15px' onClick={ () => this.setState({actionType:2,initValue:record,isShow:true,softwarePackage:record.softwarePackage})}>编辑</a>
                        <a onClick={ () => this.delete(record.id) }>删除</a>
                    </>
                }
            },
        ];

        const columns1 = [
            {
                title:'打印机名称',
                dataIndex:'name'
            },
            {
                title:'描述',
                dataIndex:'description'
            },
            {
                title:'分类',
                dataIndex:'system'
            },
            {
                title:'版本',
                dataIndex:'version'
            },
            {
                title:'大小',
                dataIndex:'size'
            },
            {
                title:'是否启用',
                dataIndex:'status',
                render:(text,record) => {
                    return <Switch checked={ text ? true : false } onChange={(e) => this.onSwitchChange(e,record,'status')}/>
                }
            },
            {
                title:'操作',
                dataIndex:'action',
                render:(text,record) => {
                    return <>
                        <a className='margin-15px' onClick={ () => this.setState({actionType:1,initValue:record,isShow1:true,softwarePackage:record.softwarePackage,size:record.size})}>查看</a>
                        <a className='margin-15px' onClick={ () => this.setState({actionType:2,initValue:record,isShow1:true,softwarePackage:record.softwarePackage,size:record.size})}>编辑</a>
                        <a onClick={ () => this.delete(record.id) }>删除</a>
                    </>
                }
            },
        ];

        const pagination = {
            total,
            current:1,
            pageSize:10,
            showQuickJumper:true,
            onChange:this.selectSoftwareList
        };

        const menu = (
            <Menu>
              <Menu.Item>
                <a href="javascript:void(0)" onClick={ () => this.delete() }>
                  删除
                </a>
              </Menu.Item>
            </Menu>
          );   

        return (
            <div className='software'>
                <ul className='software-nav'>
                    <li className={ nav == 0 ? 'software-nav__item-active' : '' } onClick={ () => this.onChange(0)}>软件下载</li>
                    <li className={ nav == 1 ? 'software-nav__item-active' : '' } onClick={ () => this.onChange(1)}>驱动下载</li>
                </ul>
                <div className='product'>
                <div className='product-title'>
                        <span></span>
                        <div className='product-title__right'>
                            <Button type='primary' onClick={() => this.setState({[nav ? 'isShow1' : 'isShow']:true,actionType:2})}>添加</Button>
                        </div>
                    </div>
                    <Table 
                        columns={ nav ? columns1 : columns }
                        dataSource={ list }
                        pagination={ pagination }
                        rowKey='id'
                        rowSelection={{
                            selectedRowKeys,
                            onSelect:(selectItem,selected,selectedList) => this.setState({selectedRowKeys:this.selectedRowKeys(selectedList)}),
                            onSelectAll:(selected,selectedList,selectItems) => this.setState({selectedRowKeys:this.selectedRowKeys(selectedList)})
                        }}
                    />
                    <div className='product-batch'>
                        <Dropdown overlay={menu} placement="bottomLeft">
                            <Button>批量操作</Button>
                        </Dropdown>
                    </div>
                
                </div>
                {isShow ? this.addOrUpdaterModal() : ''}
                {isShow1 ? this.addOrUpdateqModal() : ''}
            </div>
        )
    }
}