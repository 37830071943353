import React from 'react';
import {
    DatePicker,
    Table,
    Modal
} from 'antd';

const RangePicker = DatePicker.RangePicker;
const confirm = Modal.confirm;

export default class Feedback extends React.Component{

    constructor(props){
        super();
        this.state = {
            list:[],
            total:0,
            startTimeString:'',
            endTimeString:''
        }
    
        this.$Ajax = props.Ajax;
    }

    componentDidMount(){

        this.getProductList(1);
    }

    getProductList = (pageNum) => {
        let { startTimeString, endTimeString } = this.state;

        this.$Ajax('sjy/equipment/officialwebsite/selectFeedBackList',{pageSize:10,pageNum,startTimeString,endTimeString})
            .then((res) => {
              this.setState({
                  list:res.rows,
                  total:res.total
              })
        })
    }

    delete = (id) => {
        let { pageNum } = this.state;
        let { showTip } = this.props;

        confirm({
            title:'是否删除？',
            okText:'确认',
            cancelText:'取消',
            onOk:() => {
                this.$Ajax('sjy/equipment/officialwebsite/deleteFeedBack',{id})
                    .then((res) => {
                        showTip('删除成功');
                        this.getProductList(pageNum);
                    })
            },
            onCancel(){}
        })
    }


    render(){
        let { list, total } = this.state;

        const columns = [
            {
                title: '姓名',
                dataIndex: 'name',
            },
            {
                title: '提交时间',
                dataIndex: 'createTimeString',
            },
            {
                title: '手机号码',
                dataIndex: 'telephone'
            },
            {
                title: '邮箱',
                dataIndex: 'email'
            },
            {
                title: '公司名称',
                dataIndex: 'companyName'
            },
            {
                title: '反馈内容',
                dataIndex: 'content'
            },
            {
                title: '操作',
                dataIndex: 'age',
                render: (text,record) =>  <a onClick={ () => this.delete(record.id)}>删除</a>
            },
        ];

        const pagination = {
            total,
            current:1,
            pageSize:10,
            showQuickJumper:true,
            onChange:this.getProductList
        };          

        return (
            <div className='product'>
                <div className='product-title'>
                    <RangePicker 
                        format="YYYY-MM-DD HH:mm"
                        onChange={(value, dateString) => this.setState({startTimeString:dateString[0],endTimeString:dateString[1]},() => this.getProductList(1))}/>
                </div>
                <Table 
                    columns={ columns }
                    dataSource={ list }
                    pagination={ pagination }
                    rowKey='id'
                />   
            </div>
        )
    }
}